import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import ImageHeaderTemplate from "../components/image-header-template";
import Layout from "../components/layout";
import { Card, CardGroup } from "react-bootstrap";
import { GatsbyImage } from "gatsby-plugin-image";
import { Helmet } from 'react-helmet';
import commonMessageValues from '../templates/formatjs-message-values';

const BanksMicroFinance = () => {
  const intl = useIntl();
  const m = useIntl().messages;

  const data = useStaticQuery(graphql`{
    domestic_card: file(relativePath: {eq: "atm_card.jpg"}) {
      childImageSharp {
        gatsbyImageData(
          height: 1000
          quality: 70
          transformOptions: {cropFocus: ATTENTION}
          layout: FULL_WIDTH
        )
      }
    }
    merchant_driving: file(
      relativePath: {eq: "merchant_driving_and_monitoring.jpg"}
    ) {
      childImageSharp {
        gatsbyImageData(
          height: 1000
          quality: 70
          transformOptions: {cropFocus: ATTENTION}
          layout: FULL_WIDTH
        )
      }
    }
    international_payment_scheme: file(
      relativePath: {eq: "international_payment_scheme.jpg"}
    ) {
      childImageSharp {
        gatsbyImageData(
          height: 1000
          quality: 70
          transformOptions: {cropFocus: ATTENTION}
          layout: FULL_WIDTH
        )
      }
    }
    sms_banking: file(relativePath: {eq: "sms_banking.jpg"}) {
      childImageSharp {
        gatsbyImageData(
          height: 1000
          quality: 70
          transformOptions: {cropFocus: ATTENTION}
          layout: FULL_WIDTH
        )
      }
    }
    ivr: file(relativePath: {eq: "ivr.png"}) {
      childImageSharp {
        gatsbyImageData(
          height: 1000
          quality: 70
          transformOptions: {cropFocus: ATTENTION}
          layout: FULL_WIDTH
        )
      }
    }
  }
  `);


  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>{m.banks_and_microfinance}</title>
      </Helmet>
      <ImageHeaderTemplate title={`${m.banks_and_microfinance}`}/>

      <CardGroup className={(intl.locale === "en" ? "text-ltr" : "text-rtl")}>
        <Card>
          <Card.Title><h1>{m.banks_and_microfinance}</h1></Card.Title>
          <div className="ui text container">
            <div className="ui bulleted list">
              <div className="item">{m.debit}</div>
              <div className="item">{m.credit}</div>
              <div className="item">{m.prepaid}</div>
            </div>
          </div>
          <Card.Text>
            <p>
              <FormattedMessage id="message_domestic_card_scheme" values={commonMessageValues}/>
            </p>
          </Card.Text>

        </Card>
        <Card className="text-center">
          <GatsbyImage image={data.domestic_card.childImageSharp.gatsbyImageData} alt="logo"/>
        </Card>
      </CardGroup>

      <CardGroup className={(intl.locale === "en" ? "text-ltr" : "text-rtl")}>
        <Card className="text-center">
          <GatsbyImage image={data.merchant_driving.childImageSharp.gatsbyImageData} alt="logo"/>
        </Card>
        <Card>
          <Card.Title><h1>{m.merchant_driving_and_monitoring}</h1></Card.Title>
          <Card.Text>
            <p>
              <FormattedMessage id="message_merchant_driving_and_monitoring" values={commonMessageValues}/>
            </p>
          </Card.Text>

        </Card>
      </CardGroup>
      <CardGroup className={(intl.locale === "en" ? "text-ltr" : "text-rtl")}>
        <Card>
          <Card.Title><h1>{m.access_to_international_payment_schemes}</h1></Card.Title>
          <Card.Text>
            <p>
              <FormattedMessage id="message_access_to_international_payment_schemes" values={commonMessageValues}/>
            </p>
          </Card.Text>
        </Card>
        <Card className="text-center">
          <GatsbyImage
            image={data.international_payment_scheme.childImageSharp.gatsbyImageData}
            alt="logo"/>
        </Card>
      </CardGroup>

      <CardGroup className={(intl.locale === "en" ? "text-ltr" : "text-rtl")}>
        <Card className="text-center">
          <GatsbyImage image={data.sms_banking.childImageSharp.gatsbyImageData} alt="logo"/>
        </Card>
        <Card>
          <Card.Title><h1>{m.sms_and_mobile_banking}</h1></Card.Title>
          <Card.Text>
            <p>
              <FormattedMessage id="message_sms_banking" values={commonMessageValues}/>
            </p>
          </Card.Text>

        </Card>
      </CardGroup>
      <CardGroup className={(intl.locale === "en" ? "text-ltr" : "text-rtl")}>
        <Card>
          <Card.Title><h1>{m.ivr_banking}</h1></Card.Title>
          <Card.Text>
            <div className="ui text container">
              <div className="ui text container">
                {m.message_ivr_banking}
              </div>
              <div className="ui bulleted list">
                <div className="item">{m.message_ivr_banking_item1}</div>
                <div className="item">{m.message_ivr_banking_item2}</div>
                <div className="item">{m.message_ivr_banking_item3}</div>
              </div>
            </div>
          </Card.Text>
        </Card>
        <Card className="text-center">
          <GatsbyImage image={data.ivr.childImageSharp.gatsbyImageData} alt="logo"/>
        </Card>
      </CardGroup>
    </Layout>
  );
};

export default BanksMicroFinance;